<template>
  <div class="api">
    <div class="apitop">
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="img">
              <el-image
                style="width: 500px; height: 390px"
                :src="data.image"
                fit="contain"
              ></el-image></div
          ></el-col>
          <el-col :span="12"
            ><div class="fangshi">
              <h2>{{ data.name }}</h2>
              <p>
                {{ data.content }}
              </p>
            </div></el-col
          >
        </el-row>
      </div>
      <el-image
        style="width: 100%; height: 135px"
        :src="require('@/util/images/index/pic_hedaerwave.png')"
        fit="contain"
      ></el-image>
    </div>
    <div class="apibottom">
      <div class="contanier">
        <p>请在下面与我们联系以获取访问权限。</p>
        <p>
          使用我们的API，您将能够创建帐户/登录页面，并以编程方式在其中输入链接。
        </p>
        <p>给我们发送电子邮件至{{ email.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { articleApi, index } from "../../api/userapi";
export default {
  name: "",

  components: {},

  data() {
    return { data: "", email: "" };
  },

  mounted() {
    this.getinfo();
  },

  methods: {
    getinfo() {
      articleApi().then(res => {
        this.data = res.list[0];
      });
      index().then(res => {
        this.email = res.config;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.api {
  .apitop {
    // height: 350px;
    background: #fcfefe;
    padding-top: 50px;
    .fangshi {
      padding-top: 110px;
      text-align: left;
      color: #000000;
      padding-left: 30px;
      h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
      }
      p {
        font-size: 18px;
        line-height: 30px;
      }
    }
    .img {
      text-align: left;
    }
  }
  .apibottom {
    background: #fff;
    padding: 100px 0;
    p {
      font-size: 18px;
      color: #333333;
      line-height: 30px;
      text-align: center;
    }
  }
}
</style>
